import { BrowserRouter as Router, Route } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import Header from './components/Header';
import Footer from './components/Footer';
import HomeScreen from './screens/HomeScreen';
import ProjectsScreen from './screens/ProjectsScreen';
import ProjectScreen from './screens/ProjectScreen';
import SkillsScreen from './screens/SkillsScreen';
import CVScreen from './screens/CVScreen';

const App = () => {
  return (
    <Router>
      <Header />
      <main className='py-3'>
        <Container>
          <Route path='/' component={HomeScreen} exact />
          <Route path='/projects' component={ProjectsScreen} exact />
          <Route path='/project/:id' component={ProjectScreen} exact />
          <Route path='/skills' component={SkillsScreen} exact />
          <Route path='/cv' component={CVScreen} exact />
        </Container>
      </main>
      <Footer />
    </Router>
  );
};

export default App;
