import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import axios from 'axios';

const CVScreen = () => {
  const [pdfPath, setPdfPath] = useState('');

  useEffect(() => {
    const fetchPDF = async () => {
      const { data } = await axios.get('/api/cv/download');

      var file = new Blob([data], { type: 'application/pdf' });
      var fileURL = URL.createObjectURL(file);
      window.open(fileURL);
      // const url = window.URL.createObjectURL(new Blob());
      // const link = document.createElement('a');
      // link.href = url;
      // link.setAttribute('download', `Baris Dincer CV.pdf`);

      // // Append to html link element page
      // document.body.appendChild(link);

      // // Start download
      // link.click();

      // // Clean up and remove the link
      // link.parentNode.removeChild(link);
      setPdfPath(data);
    };

    fetchPDF();
  }, []);

  return (
    <>
      <Row>
        <Col>CV is downloading...</Col>
      </Row>
    </>
  );
};

export default CVScreen;
