import React from 'react';
import { Row, Col } from 'react-bootstrap';

const Experience = ({ value, name }) => {
  const maxValue = 5;
  const experienceArray = Array.from(Array(maxValue).keys());
  return (
    <div className='experience experience-margin'>
      <Row>
        <Col>{name}</Col>
        <Col>
          {experienceArray.map((exp, index) => (
            <span key={index}>
              {index <= value ? (
                <i className='fas fa-square'></i>
              ) : (
                <i className='far fa-square'></i>
              )}
            </span>
          ))}
        </Col>
      </Row>
    </div>
  );
};

export default Experience;
