import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import Project from '../components/Project';
import axios from 'axios';

const ProjectsScreen = () => {
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    const fetchProjects = async () => {
      const { data } = await axios.get('/api/projects');
      setProjects(data);
    };

    fetchProjects();
  }, []);
  return (
    <>
      <Row>
        {projects.map((project) => (
          <Col key={project._id} sm={12} md={6} lg={6} xl={4}>
            <Project project={project} />
          </Col>
        ))}
      </Row>
    </>
  );
};

export default ProjectsScreen;
