import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import axios from 'axios';
import Skill from '../components/Skill.js';

const SkillsScreen = () => {
  const [skills, setSkills] = useState([]);

  useEffect(() => {
    const fetchSkills = async () => {
      const { data } = await axios.get('/api/skills');
      setSkills(data);
    };

    fetchSkills();
  }, []);

  return (
    <>
      <Row>
        {skills.map((skill) => (
          <Col key={skill._id} sm={12} md={12} lg={4} xl={4}>
            <Skill skill={skill} />
          </Col>
        ))}
      </Row>
    </>
  );
};

export default SkillsScreen;
