import React from 'react';
import { Card, Row, Col } from 'react-bootstrap';
import Experience from './Experience.js';

const Skill = ({ skill }) => {
  return (
    <Card className='my-3 p-3 rounded'>
      {/* <Card.Img src={project.image} variant='top' /> */}
      <Card.Body>
        <Card.Title as='div' className='project-title'>
          <strong>{skill.group}</strong>
          <Row>
            {skill.items.map((item) => (
              <Col key={item._id} sm={12}>
                <Card.Text as='div' className='py-2'>
                  <Experience name={item.name} value={item.experience} />
                </Card.Text>
              </Col>
            ))}
          </Row>
        </Card.Title>
        {/* <Card.Text as='div'>{project.description}</Card.Text> */}
      </Card.Body>
    </Card>
  );
};

export default Skill;
