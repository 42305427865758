import React from 'react';
import { Row, Col, Image, Container } from 'react-bootstrap';

const calculateTime = (startDate) => {
  return Math.floor((new Date() - new Date(startDate).getTime()) / 3.15576e10);
};

const HomeScreen = () => {
  const age = calculateTime('1988-08-05');
  const since = calculateTime('2019-08-14');
  return (
    <>
      <Row>
        <Col
          md={3}
          className='d-flex align-items-center justify-content-center py-3'
        >
          <Container>
            <Row>
              <Col sm={12}>
                <Image
                  src='./images/BD.JPG'
                  alt='Baris Dincer'
                  fluid
                  roundedCircle
                  thumbnail
                />
              </Col>
            </Row>
            <Row className='justify-content-center py-3'>
              <Col
                md='auto'
                sm={4}
                className='no-color-link bigger-icons d-flex align-items-center justify-content-center'
              >
                <a
                  href='mailto:barisdincer8@gmail.com'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <i className='far fa-envelope'></i>
                </a>
              </Col>
              <Col
                md='auto'
                sm={4}
                className='no-color-link bigger-icons d-flex align-items-center justify-content-center'
              >
                <a
                  href='https://www.linkedin.com/in/brsdncr/'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <i className='fab fa-linkedin'></i>
                </a>
              </Col>
              <Col
                md='auto'
                sm={4}
                className='no-color-link bigger-icons d-flex align-items-center justify-content-center'
              >
                <a
                  href='https://www.github.com/brsdncr/'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <i className='fab fa-github'></i>
                </a>
              </Col>
            </Row>
          </Container>
        </Col>
        <Col
          md={9}
          className='d-flex align-items-center justify-content-center py-3'
        >
          <Container>
            <Row>
              <h1>Hello! I'm Baris.</h1>
              <h3>
                A technology enthusiast and lifelong learner based in Amsterdam,
                Netherlands.
              </h3>
            </Row>
          </Container>
        </Col>
      </Row>
      <Row className='home-info'>
        <Col
          md={12}
          className='d-flex align-items-center justify-content-center py-3'
        >
          <Container>
            <Row>
              <Col>
                <h6>
                  I am originally from&nbsp;
                  <a
                    href='https://en.wikipedia.org/wiki/%C4%B0zmir'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    Izmir
                  </a>
                  , Turkey and I'm&nbsp;
                  {age}
                  &nbsp;years old.
                </h6>
                <h6>
                  I have been living in&nbsp;
                  <a
                    href='https://en.wikipedia.org/wiki/Netherlands'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    The Netherlands
                  </a>{' '}
                  for {since} years now.
                </h6>
                <h6>
                  I hold a <span>BSc. Computer Science and Engineering</span>
                  &nbsp;degree from&nbsp;
                  <a
                    href='https://www.sabanciuniv.edu/en'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    Sabanci University
                  </a>
                  , Istanbul and <span>MSc. Management </span> degree from&nbsp;
                  <a
                    href='https://www.brunel.ac.uk/'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    Brunel University
                  </a>
                  , London.
                </h6>
                <h6>
                  Some of my hobbies are software development, game development,
                  playing guitar, playing chess.
                </h6>
                <h6>
                  Also, travelling the world to try new cuisines. Oh and I cook
                  too. 👨🏼‍🍳
                </h6>
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
    </>
  );
};

export default HomeScreen;
