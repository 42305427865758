import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { Row, Col, ListGroup, Button, Image } from 'react-bootstrap';

const ProjectScreen = ({ match }) => {
  const [project, setProject] = useState([]);

  useEffect(() => {
    const fetchProject = async () => {
      const { data } = await axios.get(`/api/projects/${match.params.id}`);
      setProject(data);
    };

    fetchProject();
  }, [match]);
  return (
    <>
      <Link className='btn my-3 icon-margin' to='/projects'>
        <i class='fas fa-chevron-left'></i>Return
      </Link>
      <Row>
        <Col md={12}>
          <ListGroup variant='flush'>
            <ListGroup.Item>
              <Row>
                <Col md={10}>
                  <h1 style={{ marginBottom: 0 }}>{project.name}</h1>
                </Col>
                <Col md={2} className='d-flex align-items-center'>
                  {project.link && (
                    <Button
                      variant='outline-success'
                      href={project.link}
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      Go to {project.name}
                    </Button>
                  )}
                </Col>
              </Row>
            </ListGroup.Item>
            <ListGroup.Item>
              {project.description}{' '}
              {project.github_link && (
                <a
                  href={project.github_link}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  {project.github_link}
                </a>
              )}
            </ListGroup.Item>
            <ListGroup.Item>
              <Row>
                <Col>
                  <Image src={project.image} alt={project.name} fluid />
                </Col>
              </Row>
            </ListGroup.Item>
          </ListGroup>
        </Col>
      </Row>
    </>
  );
};

export default ProjectScreen;
